import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SvgIcon from '../Icon/SvgIcon';
import s from './ChipList.module.scss';
import { darkGreen } from '../../core/colors';

function ChipList(props) {
  const {
    className,
    icon,
    label,
    emptyText = 'n/a',
    iconColor = darkGreen,
    children = [],
    style,
  } = props;

  return (
    <div className={cx(s.root, className)} style={style}>
      {icon && (
        <div className={s.icon}>
          <SvgIcon fill={iconColor} icon={icon} title={label} />
        </div>
      )}
      {children.length === 0 ? (
        <span className={s.emptyText}>{emptyText}</span>
      ) : (
        children.map((x, i) => (
          <div key={i} className={s.chip}>
            {x}
          </div>
        ))
      )}
    </div>
  );
}

ChipList.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  emptyText: PropTypes.string,
  iconColor: PropTypes.string,
  children: PropTypes.node,
};

export default ChipList;
