import gql from 'graphql-tag';
import config from '../../config';
import { notify } from '../actions/ui';
import { GraphQLClient, basicAuth } from './api';
import { APIError } from './apiTransport';
import { createToken } from './auth';
import { clearInvitationToken, getInvitationToken } from './invite';
import { clearTracking } from './tracking';
import { clearCache, pathAndQuery, setCache } from './util';

const defaultNext = '/dashboard';

function isSuccess(status) {
  return status >= 200 && status < 300;
}

function isUnauthorized(status) {
  return status === 401;
}

function post(path, data) {
  return fetch(path, {
    method: 'POST',
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(data),
  });
}

export function logout() {
  clearCache('access_token');
  clearCache('user_context');
}

export async function loginAs(client, store, userId, next = defaultNext) {
  const data = await client.send(
    gql`
      query getUser($userId: String!) {
        user(id: $userId) {
          id
          username
        }
      }
    `,
    { userId }
  );

  if (!data.user) {
    throw new Error('User not found');
  }

  userId = data.user.id;
  try {
    const token = await createToken(client, userId, {
      method: 'admin',
      ua: Navigator.userAgent,
    });
    logout();

    setCache('access_token', token);
    window.location = next;
  } catch {
    await store.dispatch(
      notify("You don't have permission to perform this action", 'error')
    );
    window.location = `/profile/${data.user.username}`;
  }
}

async function createUser(client, data) {
  const res = await client.send(
    gql`
      mutation _(
        $email: String!
        $password: String!
        $first_name: String!
        $last_name: String!
        $recaptcha_token: String
        $landing_url: String
        $invite: String
        $tags: [String]
        $signup_type: SignupType
        $subdomain: String
        $phone: String
        $agreements: [AgreementInput]
      ) {
        createUser(
          email: $email
          password: $password
          first_name: $first_name
          last_name: $last_name
          recaptcha_token: $recaptcha_token
          landing_url: $landing_url
          invitation_token: $invite
          tags: $tags
          signup_type: $signup_type
          subdomain: $subdomain
          phone: $phone
          agreements: $agreements
        ) {
          user {
            id
          }
          next_url
        }
      }
    `,
    data
  );

  return res.createUser;
}

export async function signup(client, data, next = defaultNext) {
  data.invite = data.invite || getInvitationToken();
  data.login = data.login === undefined ? true : data.login;

  let json;
  try {
    json = await createUser(client, data);
  } catch (err) {
    if (!(err instanceof APIError)) {
      throw err;
    }
    if (['otp_enrollment_required', 'otp_required'].includes(err.message)) {
      window.location = next;
      return;
    } else {
      throw err;
    }
  }

  client = new GraphQLClient(
    config.apiUrl,
    basicAuth(data.email, data.password)
  );

  const token = await createToken(client, undefined, {
    method: 'password',
    signup: true,
    signupType: data.signup_type || undefined,
    landingUrl: data.landing_url,
    ua: Navigator.userAgent,
  });

  clearInvitationToken();
  clearTracking();

  if (json.next_url) {
    try {
      const nextUrl = new URL(json.next_url);
      next = next ? `${next}?next=${pathAndQuery(nextUrl)}` : json.next_url;
    } catch (err) {
      // ignore
    }
  }

  setCache('access_token', token);
  window.location = next;
}

export default async function login({
  email,
  password,
  otp,
  recaptchaToken,
  next = defaultNext,
}) {
  const inviteToken = getInvitationToken();

  const client = new GraphQLClient(config.apiUrl, basicAuth(email, password), {
    otp,
  });

  const token = await createToken(
    client,
    undefined,
    {
      method: 'password',
      inviteToken,
      ua: Navigator.userAgent,
    },
    recaptchaToken
  );

  clearInvitationToken();

  setCache('access_token', token);
  window.location = next;
}
